<template>
  <v-card elevation="1">
    <v-form ref="form" v-model="valid" lazy-validation class="pt-4">
      <v-row class="px-6 pt-2">
        <v-col cols="6">
          <v-text-field
            v-model="formData.name"
            placeholder="Digite o nome"
            outlined
            dense
            :clearable="true"
            label="Nome"
            required
            :rules="rules.genericRules"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="px-6 pb-4">
      <v-btn
        depressed
        color="fmq_gray"
        dark
        @click="send"
        :loading="loadingBtn"
        >{{ isEdit ? "Editar" : "Criar" }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { rules } from "@/utils/rules.js";
export default {
  name: "AnatomiasFisiologiasFormulario",
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    formData: {
      name: null,
    },
    rules: rules,
    valid: true,
    isEdit: false,
  }),
  created() {
    if (this.$route.name === "AnatomiasFisiologiasEditar") {
      this.formData = this.item;
      this.isEdit = true;
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    send() {
      if (this.formValid) this.$emit("send", this.formData);
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
  },
};
</script>
